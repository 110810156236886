<template>
  <v-container fluid class="keystone-tool-container pl-14 ml-0">
    <v-row>
      <v-col>
        <SelectDSP v-on:dsp="changeDSP"></SelectDSP>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SelectTool v-bind:dsp="selectedDsp" key="selectTool"></SelectTool>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectDSP from '../components/KeystoneToolsComponents/SelectDSP'
import SelectTool from '../components/KeystoneToolsComponents/SelectTool'
import { baseViewMixin } from '@/mixins/baseViewMixin'
export default {
  name: 'Keystone-tools.vue',
  components: {
    SelectDSP,
    SelectTool
  },
  mixins: [baseViewMixin],
  data: function () {
    return {
      selectedDsp: null
    }
  },
  methods: {
    changeDSP: function (dsp) {
      this.selectedDsp = dsp
    }
  },
  mounted () {
    this.onMounted()
  }
}
</script>

<style scoped>

</style>
